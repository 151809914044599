import NextLink from "next/link";
import {GridElementModal} from "@/components/clapps/Modals";
import Image from 'next/image'
import imageShowcaseBg from '../../../public/assets/clapps/images/landing-grid-bg-eshowcase.webp';
import imageShopBg from '../../../public/assets/clapps/images/landing-grid-bg-eshop-alt.webp';
import image1CBg from '../../../public/assets/clapps/images/landing-grid-bg-1c.webp';
import imageModalBg from '../../../public/assets/clapps/images/landing-grid-bg-modal.webp';
import clsx from "clsx";
import * as React from "react";

function GridElement(props){
    return(
        <div className={'lan3_el'}>
            <h3 className={'lan3_el_h3'}>
                {props.title}
            </h3>
            <p className={'lan3_el_p'}>
                {props.text}
            </p>
            <div className={'lan3_el_block'}>
                <div className={'lan3_el_block2'}>
                    <p className={'lan3_el_block2_price'}>
                        {props.price}
                    </p>
                    {props.badge && (
                        <p className={clsx(props.badge.className, 'lan3_el_block2_p')}>
                            {props.badge.text}
                        </p>
                    )}
                </div>
            </div>
            <div className={'mt-4 sm:mt-8'}>
                {props.link && (
                    <NextLink href={props.link} className={'mt-4 xl:mt-8 text-sm sm:text-lg xl:text-22px text-black-1 hover:text-red-1 underline underline-offset-[4px] lg:underline-offset-[6px] decoration-1'}>подробнее</NextLink>
                )}
            </div>
            {props.icon && (
                <div className={'lan2_li_modal_img'}>
                    <Image
                        src={props.icon}
                        alt="ICON"
                        quality={100}
                        sizes="100vw"
                        style={{
                            // width: '100%',
                            // height: '100%',
                        }}
                    />
                </div>
            )}
        </div>
    )
}

export default function LandingSection3(props){
    return(
        <section id={'solutions'} className={clsx(props.sectionClass, 'lan3')}>
            {(!props.screenMobile && props.breadcrumbCurrent) && (
                <div className={'flex max-w-300 w-full py-12 text-normal'}>
                    <nav>
                        <ul className={'flex flex-row'}>
                            <li>
                                <NextLink href={'/'} className={'text-black-1 hover:text-red-1'}>Главная</NextLink>
                            </li>
                            {(props.breadcrumbLink && props.breadcrumbRoot) && (
                                <li>
                                    <span className={'px-1.5'}>&gt;</span>
                                    <NextLink href={props.breadcrumbLink} className={'text-black-1 hover:text-red-1'}>{props.breadcrumbRoot}</NextLink>
                                </li>
                            )}
                            <li className={'text-red-1'}>
                                <span className={'px-1.5'}>&gt;</span>
                                {props.breadcrumbCurrent}
                            </li>
                        </ul>
                    </nav>
                </div>
            )}
            {props.infoTitle ? (
                <h2 className={props.infoTitle.className}>
                    {props.infoTitle.text}
                </h2>
            ) : (
                <h2 className={'lan3_h2'}>
                    Готовые решения
                </h2>
            )}
            {props.infoText ? (props.infoText.map((p,i) =>
                <React.Fragment key={i}>
                    <p className={p.className}>
                        {p.text}
                    </p>
                </React.Fragment>
            )) : (
                <p className={'lan3_p'}>
                    Хотите купить готовый сайт или решение для сайта? Выберите подходящий вариант для своего бизнеса, и мы максимально быстро запустим ваш проект.
                </p>
            )}
            <div className={'lan3_grid'}>
                <GridElement
                    title={'Интернет-витрина'}
                    text={'Каталог товаров с ручным наполнением, дополнительные интеграции по запросу. Доступный вариант для самого быстрого старта продаж.'}
                    price={'от 80 000 руб.'}
                    // link={'/'}
                    badge={{
                        text: 'хит продаж',
                        className: 'yellow-notch-bg'
                    }}
                    icon={imageShowcaseBg}
                />
                <GridElement
                    title={'Интернет-магазин'}
                    text={'Готовый интернет-магазин с полным набором интеграций: системы 1С, методы оплаты, доставки и т.д. Готовое решение для вашего бизнеса всего за 14 дней.'}
                    price={'от 150 000 руб.'}
                    link={'/ready/lp-buy'}
                    badge={{
                        text: props.screenMobile ? 'выгодно' : 'выгодное решение',
                        className: 'blue-notch-bg'
                    }}
                    icon={imageShopBg}
                />
                <GridElement
                    title={'Web-сервис 1с: (унф)'}
                    text={'Настройка обмена 1С: УНФ. Методы непрерывного обмена между интернет-магазином и 1С через web-сервисы. Не требует запуска программы.'}
                    price={'от 60 000 руб.'}
                    icon={image1CBg}
                    // link={'/'}
                />
                <GridElementModal
                    title={'Хотите купить готовое решение?'}
                    text={[
                        'Отправьте нам заявку или оставьте контактный номер телефона, и мы свяжемся с вами совсем скоро.',
                        'Давайте вместе сделаем ваш бизнес успешнее с помощью передовых IT-решений!'
                    ]}
                    icon={imageModalBg}
                    handleOpenModal={props.handleOpenModal}
                />
            </div>
        </section>
    )
}