import * as React from "react";
import TextField from "@mui/material/TextField";
import {styled} from "@mui/material/styles";
import {useForm} from "react-hook-form";
import bg1Img from '../../../public/assets/clapps/images/section-6-bg-1.svg';
import bg2Img from '../../../public/assets/clapps/images/section-6-bg-2.svg';
import Image from "next/image";
import LoadingButton from "@mui/lab/LoadingButton";
import axios from "axios";

const StyledInput = styled(TextField) (({theme}) => ({
    marginBottom: '1rem',
    [theme.breakpoints.up('sm')]: {
        fontSize: '1.25rem',
    },
    '& label': {
        color: '#FFFFFF',
        fontSize: '14px',
        [theme.breakpoints.up('sm')]: {
            fontSize: '22px',
        },
    },
    '& label.Mui-focused': {
        color: '#FF5330',
    },
    '& .MuiInput-input' : {
        color: '#FFFFFF',
        fontSize: '14px',
        overflow: 'hidden',
        [theme.breakpoints.up('sm')]: {
            fontSize: '22px',
        },
    },
    // '& .MuiInput-underline': {
    //     marginTop: '22px'
    // },
    '& .MuiInput-underline:before': {
        borderWidth: '2px',
        borderBottomColor: '#FFFFFF',
    },
    '&:hover .MuiInput-underline:before': {
        borderBottomColor: '#FFFFFF!important',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#FF5330',
    },
    '& textarea':{
        paddingTop: '2px',
        paddingBottom: '0px',
        [theme.breakpoints.up('sm')]: {
            paddingTop: '4px',
            paddingBottom: '2px',
        },
    }
}))

export default function LandingSection6(props){
    const [loading, setLoading] = React.useState(false);
    const { getValues, control, reset, register, handleSubmit, setError, clearErrors, formState: { errors } } = useForm();
    //inputs
    const [inputTextLength, setInputTextLength] = React.useState({
        name: 0,
        phone: 0,
    })
    const handleInputTextLength = e => {
        const {name, value} = e.target;
        setInputTextLength({...inputTextLength,[name]:value.length});
    }

    const onError = (errors, e) => {
        // console.log(errors, e);
        clearErrors()
    }

    async function onSubmit(data){
        let validName = data.name && data.name.trim().length > 2;
        let validEmail = !data.email || /.+@.+\.[A-Za-z]+$/.test(data.email);
        let validPhone = data.phone && data.phone.replace(/[^0-9]/g,'').length === 11;
        setLoading(true)
        data.sendTo = 'mail@clapps.ru'
        data.subject = 'Заказ на разработку'

        if(!validName){
            setError('name', { type: 'custom', message: 'Имя не указано!' })
        }

        if(!validEmail){
            setError('email', { type: 'custom', message: 'Email не корректен!' })
        }

        if(!validPhone){
            setError('phone', { type: 'custom', message: 'Телефон не указан!' })
        }

        if(validEmail && validPhone && validName){
            const text = `Заявка на разработку (clapps.ru)`
            const html = `<h1>Заявка на разработку!</h1>
            <p>Имя: ${data.name}<p>
            <p>Email: ${data.email}<p>
            <p>Телефон: ${data.phone}<p>
            <p>О проекте: ${data.comment}<p>`

            const response = await axios.post(`/api/sendmail`, { email: data.sendTo, subject: data.subject, text, html})
            if(response.data.error){
                setError('all', { type: 'custom', message: response.data.error })
            }
            if(response.data.data){
                reset()
                props.handleOpenModal();
            }
        }
        setLoading(false);
    }

    return(
        <section id={'feedback'} className={'flex flex-col w-full mt-10 sm:mt-20 lg:mt-40 xl:mt-[12.5rem] items-center justify-center bg-black-2'}>
            <div className={'px-6 xl:px-0 relative flex flex-col max-w-300 w-full items-center justify-center'}>
                <h2 className={'z-10 pt-10 text-lg sm:text-46px text-white text-center font-black leading-snug'}>
                    {props.title}
                </h2>
                <p className={'z-10 pt-8 sm:pt-10 pb-8 sm:pb-20 text-sm sm:text-lg xl:text-22px text-white text-center leading-relaxed'}>
                    {props.text}
                </p>
                <div className={'absolute right-6 top-16 sm:top-24 origin-right scale-75 sm:scale-100'}>
                    <Image
                        src={bg1Img}
                        alt="ICON"
                        quality={100}
                        sizes="100vw"
                        style={{
                            width: 'auto',
                            height: 'auto',
                        }}
                    />
                </div>
            </div>
            <form noValidate onSubmit={handleSubmit(onSubmit, onError)} className={'relative flex flex-col max-w-300 w-full px-6 xl:px-0 justify-center items-center'}>
                <StyledInput
                    fullWidth
                    {...register('name')}
                    error={!!errors.name}
                    name={'name'}
                    label={'Ваше имя'}
                    variant={'standard'}
                    required={true}
                    onInput={handleInputTextLength}
                />
                <StyledInput
                    fullWidth
                    {...register('phone')}
                    error={!!errors.phone}
                    name={'phone'}
                    label={'Номер телефона'}
                    variant={'standard'}
                    required={true}
                    onInput={handleInputTextLength}
                />
                <StyledInput
                    fullWidth
                    {...register('email')}
                    error={!!errors.email}
                    name={'email'}
                    label={'E-mail'}
                    variant={'standard'}
                />
                <StyledInput
                    fullWidth
                    {...register('comment')}
                    error={!!errors.comment}
                    name={'comment'}
                    label={'Расскажите о вашем проекте'}
                    variant={'standard'}
                    multiline={true}
                    maxRows={5}
                />
                <LoadingButton
                    type={'submit'}
                    loading={loading}
                    disabled={inputTextLength.name < 2 || inputTextLength.phone < 5}
                    className={'z-10 sm:max-w-72 w-full h-14 sm:h-16 mt-10 btn-color-filled'}
                >
                    Отправить заявку
                </LoadingButton>
                {errors.all && ( `${errors.all.message}` )}
                <div className={'z-10 pt-5 sm:pt-8 pb-15 sm:pb-10 text-[10px] sm:text-xs text-black-6 text-center'}>
                    Нажимая кнопку «Отправить заявку» я соглашаюсь с политикой конфиденциальности и обработки персональных данных
                </div>
                <div className={'absolute left-5 sm:left-0 -bottom-5 sm:bottom-6 origin-left scale-50 sm:scale-100'}>
                    <Image
                        src={bg2Img}
                        alt="ICON"
                        quality={100}
                        sizes="100vw"
                        style={{
                            width: 'auto',
                            height: 'auto',
                        }}
                    />
                </div>
            </form>
        </section>
    )
}