import NextLink from "next/link";
import {GridElementModal} from "@/components/clapps/Modals";
import Image from 'next/image'
import imageCartBg from '../../../public/assets/clapps/images/landing-grid-bg-eshop.webp';
import imageAppBg from '../../../public/assets/clapps/images/landing-grid-bg-app.webp';
import imageWebBg from '../../../public/assets/clapps/images/landing-grid-bg-business.webp';
import imageCrmBg from '../../../public/assets/clapps/images/landing-grid-bg-crm.webp';
import imageLanBg from '../../../public/assets/clapps/images/landing-grid-bg-landing.webp';
import imageModalBg from '../../../public/assets/clapps/images/landing-grid-bg-modal.webp';
import clsx from "clsx";
import * as React from "react";

function GridElement(props){
    return(
        <div className={'lan2_li'}>
            <h3 className={'lan2_li_h3'}>
                {props.title}
            </h3>
            <p className={'lan2_li_text'}>
                {props.text}
            </p>
            <div className={'lan2_li_block'}>
                <div className={'flex flex-col justify-center'}>
                    <p className={'lan2_li_price'}>
                        {props.price}
                    </p>
                    <NextLink href={props.link} className={'lan2_li_detail'}>подробнее</NextLink>
                </div>
                <div className={'lan2_li_block_img'}>
                    <Image
                        src={props.icon}
                        alt="ICON"
                        quality={100}
                        sizes="100vw"
                        style={{
                            // width: '100%',
                            // height: '100%',
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default function LandingSection2(props){
    return(
        <section id="products" className={clsx(props.sectionClass, 'lan2_nav')}>
            {(!props.screenMobile && props.breadcrumbCurrent) && (
                <div className={'flex max-w-300 w-full py-12 text-normal'}>
                    <nav>
                        <ul className={'flex flex-row'}>
                            <li>
                                <NextLink href={'/'} className={'text-black-1 hover:text-red-1'}>Главная</NextLink>
                            </li>
                            {(props.breadcrumbLink && props.breadcrumbRoot) && (
                                <li>
                                    <span className={'px-1.5'}>&gt;</span>
                                    <NextLink href={props.breadcrumbLink} className={'text-black-1 hover:text-red-1'}>{props.breadcrumbRoot}</NextLink>
                                </li>
                            )}
                            <li className={'text-red-1'}>
                                <span className={'px-1.5'}>&gt;</span>
                                {props.breadcrumbCurrent}
                            </li>
                        </ul>
                    </nav>
                </div>
            )}
            <div>
                {props.infoTitle ? (
                    <h1 className={props.infoTitle.className}>
                        {props.infoTitle.text}
                    </h1>
                ) : (
                    <h2 className={'lan2_h2'}>
                        Что можно заказать:
                    </h2>
                )}
                {props.infoText ? (props.infoText.map((p,i) =>
                    <React.Fragment key={i}>
                        <p className={p.className}>
                            {p.text}
                        </p>
                    </React.Fragment>
                )) : (
                    <p className={'lan2_p'}>
                        Создадим проект любой сложности, выполним разработку под ключ, настроим SEO вашего сайта. Стоимость работ зависит от многих факторов, но для вас мы рассчитаем её совершенно бесплатно.
                    </p>
                )}
                <div className={'lan2_ul'}>
                    <GridElement
                        title={'Интернет-магазин'}
                        text={'Создадим для вас функциональный и привлекательный онлайн-магазин с интеграцией систем 1С, методов оплаты и доставки, CRM-систем и других продуктов по индивидуальному запросу. А еще вы можете купить готовый интернет-магазин'}
                        price={'от 250 000 руб.'}
                        link={'/dev/lp-shop'}
                        icon={imageCartBg}
                    />
                    <GridElement
                        title={'Бизнес-сайт'}
                        text={'Сайт для вашей организации, который подчеркнет уникальность бизнеса и приведет новых клиентов. Корпоративный портал под ключ в фирменном стиле, личные кабинеты сотрудников, документооборот, чат-боты, виджеты.'}
                        price={'от 80 000 руб.'}
                        link={'/dev/lp-business'}
                        icon={imageWebBg}
                    />
                    <GridElement
                        title={'Landing page'}
                        text={'Сайт-одностраничник — это эффектная и недорогая презентация вашего бизнеса в интернете. Используем лучшие практики UX/UI дизайна, чтобы ваш лендинг приводил больше клиентов с минимальными вложениями.'}
                        price={'от 30 000 руб.'}
                        link={'/dev/lp-landing'}
                        icon={imageLanBg}
                    />
                    <GridElement
                        title={'Приложение'}
                        text={'Создание мобильных приложений на системах Android и iOS, разработка приложений для Windows и Linux. Приложения кафе, интернет-магазинов, обучающих программ, новостных изданий и другие.'}
                        price={'от 120 000 руб.'}
                        link={'/dev/lp-app'}
                        icon={imageAppBg}
                    />
                    <GridElement
                        title={'CRM-система'}
                        text={'Разработка и внедрение систем CRM, автоматизации бизнеса. Интеграция продуктов 1С, Битрикс 24. Создадим систему CRM под запрос именно вашего бизнеса с любыми дополнительными функциями и модулями.'}
                        price={'от 350 000 руб.'}
                        link={'/dev/lp-crm'}
                        icon={imageCrmBg}
                    />
                    <GridElementModal
                        title={'Давайте обсудим ваш проект?'}
                        text={[
                            'Отправьте нам заявку или оставьте контактный номер телефона, и мы свяжемся с вами совсем скоро.',
                            'Давайте вместе сделаем ваш бизнес успешнее с помощью передовых IT-решений!'
                        ]}
                        icon={imageModalBg}
                        handleOpenModal={props.handleOpenModal}
                    />
                </div>
            </div>
        </section>
    )
}