import * as React from "react";
import { userAgentFromString } from 'next/server';
import Head from 'next/head'
import useMediaQuery from '@mui/material/useMediaQuery';
import Header from "@/components/clapps/Header";
import LandingSection1 from "@/components/clapps/LandingSection1";
import LandingSection2 from "@/components/clapps/LandingSection2";
import LandingSection3 from "@/components/clapps/LandingSection3";
import LandingSection4 from "@/components/clapps/LandingSection4";
import LandingSection5 from "@/components/clapps/LandingSection5";
import Footer from '@/components/clapps/Footer';
import LandingSection6 from "@/components/clapps/LandingSection6";
import LandingSection7 from "@/components/clapps/LandingSection7";
import {FeedbackModal} from "@/components/clapps/Modals";

const meta = {
    title: "Разработка сайтов и приложений под ключ. IT-студия Clapps.",
    description: "Создание и продвижение сайтов на заказ. Профессиональная разработка интернет-магазинов и CRM-систем с индивидуальным дизайном и функционалом. Комплексный подход к управлению Вашим бизнесом в сети. Бесплатный расчет стоимости проектов.",
    keywords: "Clapps, Клапс, разработка сайтов, заказать сайт, купить сайт, заказать интернет-магазин, купить интернет-магазин, CRM, ЦРМ, it студия"
}

export default function Index({ device }){
    const mediaMax = useMediaQuery('(max-width:1023px)', { noSsr: true })
    const [modalState, setModalState] = React.useState(0);
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [screenMobile, setScreenMobile] = React.useState(device === 'mobile');

    const handleOpenModal = () => {
        setModalState(0)
        setIsModalOpen(true)
    }

    React.useEffect(() => {
        setScreenMobile(mediaMax)
    }, [mediaMax]);

    

    return(
        <>
            <Head>
                <title>{meta.title}</title>
                <meta name="description" content={meta.description} />
                <meta name="keywords" content={meta.keywords} />
                <meta name="og:title" property="og:title" content={meta.title} />
                <meta name="og:description" property="og:description" content={meta.description} />
                <meta property="og:url" content="https://clapps.ru" />
                <link rel="canonical" href="https://clapps.ru" />
                <meta name="viewport" content="initial-scale=1, width=device-width" />
            </Head>
            <Header device={device} />
            <main className='main_block'>
                <LandingSection1 screenMobile={screenMobile} handleOpenModal={handleOpenModal} />
                <LandingSection2 screenMobile={screenMobile} handleOpenModal={handleOpenModal} sectionClass={'mt-22 lg:mt-24 xl:mt-[10.75rem]'} />
                <LandingSection3 screenMobile={screenMobile} handleOpenModal={handleOpenModal} sectionClass={'mt-25 sm:mt-14 lg:mt-8 xl:mt-[4.5rem]'} />
                <LandingSection4 screenMobile={screenMobile} handleOpenModal={handleOpenModal} sectionClass={'mt-20 lg:mt-40 xl:mt-[12.5rem]'}/>
                <LandingSection5 screenMobile={screenMobile} sectionClass={'mt-15 sm:mt-10 lg:mt-0 xl:mt-[4.5rem] lg:pt-20'} />
                <LandingSection6
                    screenMobile={screenMobile}
                    handleOpenModal={() => {
                        setModalState(1)
                        setIsModalOpen(true)
                    }}
                    title={'Заказать разработку'}
                    text={'Мы предложим лучшие решения для вашего проекта и бесплатно рассчитаем стоимость'}
                />
                <LandingSection7/>
            </main>
            <Footer/>
            <FeedbackModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} isSent={modalState} onSent={() => setModalState(1)} />
        </>
    )
}

export async function getServerSideProps({ req, res }) {
    const userAgent = req ? req.headers['user-agent'] : window.navigator.userAgent;
    const device = userAgentFromString(userAgent)?.device.type || 'desktop'
    
    return {
      props: { device }
    }
}