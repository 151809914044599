import Button from "@mui/material/Button";
import Image from 'next/image'
import bg1Img from '../../../public/assets/clapps/images/section-1-bg.svg';

export default function LandingSection1(props){
    return(
        <div className={'relative flex w-full justify-center'}>
            <div className={'lan1_img'}>
                <Image
                    fill
                    style={{
                        objectFit: 'cover',
                        objectPosition: 'left'
                    }}
                    src={bg1Img}
                    alt="BG"
                    quality={100}
                />
            </div>
            <div className={'lan1_block'}>
                <h1 itemProp="name" className={'lan1_h1'}>
                    Разработка сайтов и приложений под ключ
                </h1>
                <p itemProp="description" className={'lan1_p'}>
                    IT-студия Clapps готова разработать для вас веб-сайт, интернет-магазин, мобильное приложение под ключ. Также вы можете заказать интеграции продуктов 1С, платежных систем, государственных сервисов.
                </p>
                <Button className={'lan1_button'} onClick={props.handleOpenModal}>
                    Отправить заявку
                </Button>
            </div>
        </div>
    )
}